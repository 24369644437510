.consumption-lora-card {
  .consumption-stat {
    span {
      font-size: 1.6rem;
      color: orange;
    }

    margin: 0 2px;

    div {
      padding: 2px 6px;
    }

    border-bottom: 4px solid orange !important;
    opacity: 0.8;
  }

  .nuConsumoFranquiaLora {
    border-bottom-color: cornflowerblue !important;

    span {
      color: cornflowerblue;
    }
  }

  .nuConsumoExcedenteLora {
    border-bottom-color: red !important;

    span {
      color: red;
    }
  }

  .nuFranquiaTotalLora {
    border-bottom-color: var(--primary-color) !important;

    span {
      color: var(--primary-color);
    }
  }

  .ant-card-body {
    height: 100%;
  }

  .consumption-card-progress-bar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 440px;
    width: 100%;
    padding: 4px;

    .consumption-card-progress-bar-percentage-value {
      padding: 0 6px;
      font-size: 0.9rem;
      font-weight: 'bold';
    }

    .consumption-card-progress-bar-content {
      width: 100%;
      border: 1px solid #dbdbdb;
      border-radius: 80px;
      height: 16px;
      display: flex;
      background: #f0f2f5;
      cursor: pointer;

      .consumption-card-progress-bar-content-main {
        height: 100%;
        text-align: center;
        color: #ffffff;
        position: relative;
      }

      .consumption-card-progress-bar-content-surplus {
        width: 20px;
        height: 100%;
        background: #ff4d4f;
        border-radius: 0 80px 80px 0;
        opacity: 0.9;
      }
    }
  }

  .consumption-card-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .consumption-card-updated-at {
      padding: 0 16px;
      opacity: 0.6;
      font-size: 0.8rem;
      margin-top: 8px;
      text-align: center;
    }
  }

  .ant-divider {
    margin: 4px;
  }

  @media (max-width: 768px) {
    .progress-consumption {
      margin: 16px 0;
    }
  }
}
@primary-color: #5867dd;@border-radius-base: 6px;