.query-builder-card {
  margin-bottom: 16px;
}

.query-builder-title {
 font-weight: bold;
}


.query-builder-dropdown-menu {
  justify-content: start;
  width: 100%;
  padding: 10px;
}
.ant-checkbox-wrapper {
  margin-left: 8px !important;
}

.query-builder-dropdown-divider {
  margin: 1px 0;
}

.query-builder-items-container {
  display: flex;
}

.query-builder-items-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.query-builder-items-row {
  max-height: 300px;
  overflow: auto;
}

.custom-select {
  display: inline-block;
}

.button-select {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.options-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 8px;
  max-width: 470px;
  justify-items: start;
  padding: 10px;
}

.options-wrapper {
  max-height: 500px;
  overflow: auto;
}

.ant-checkbox-group-item {
  display: inline-flex;
}
.ant-popover-inner-content{
  margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  .options-wrapper {
    max-height: 300px;
    overflow: auto;
  }
}
@primary-color: #5867dd;@border-radius-base: 6px;