.data-not-found-step-exclamation-circle {
  font-size: 3rem;
  font-weight: bold;
  padding: 4px 8px;
}

.data-not-found-step-paragraph {
  width: 60%;
  margin: 0 auto;
  margin-top: 16px;
  font-size: 1.1rem;
  opacity: 0.8;
}

@primary-color: #5867dd;@border-radius-base: 6px;