.logo-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100vh;
  max-width: 100vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff url('../../images/bg-3.jpg');
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-container-context-text {
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.2rem;
  opacity: 0.5;
  margin-bottom: 0;
  margin-left: 16px;
  max-width: 164px;
}

#breathing-img {
  width: 280px;
  -webkit-animation: breathing 1s ease-out infinite normal;
  animation: breathing 1s ease-out infinite normal;
  -webkit-font-smoothing: antialiased;
  text-align: center;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
