.single-range-container {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-range-pickers {
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-range-popover {
  margin: -2px;
  border-radius: 0 0 6px 6px;
  text-align: center;
  transition: 0.3s;
  border: 1px solid transparent;
}

.tags-wrapper {
  max-width: 380px;
  display: inline-block;

  .tag-quick-filters {
    margin: 4px;
    cursor: pointer;
    display: inline-block;
    font-size: 10px !important;
    &:hover {
      transform: scale(1.04);
    }
    &:active {
      filter: contrast(96%);
    }
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;