.card-click-filter {
  cursor: pointer;
  .ant-card-body {
    height: 100%;
    border-radius: 6px;
    border: 1px solid transparent;
    transition: 0.2s;
    position: relative;
  }
  .ant-card-body:hover {
    background: #fff;
    border: 1px solid #ccc;
    transform: scale(1.04);
  }

  .ant-card-body:active {
    background: #fafafa;
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;