.no-coverage-table {
  padding: 20px;
  min-width: 200px;

  > div {
    margin: 0 auto;
    margin-top: 8px;
  }

  p {
    font-size: 1rem;
    text-align: center;
    margin: 12px;
  }
}

.no-coverage-table-icon {
  color: red;
  opacity: 0.6;
  margin: 0;
  svg {
    font-size: 1.5rem;
  }
}

.coverage-table {
  td {
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    font-size: 0.9rem;
    font-weight: bold;
    opacity: 0.8;
  }
  .td-signal-bars {
    padding: 6px;
  }
  th {
    padding-bottom: 8px;
    font-size: 0.8rem;
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;