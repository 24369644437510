.status-menu {
  .ant-tabs,
  .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-nav:before {
    border-bottom: 1px solid transparent !important;
  }

  .ant-tabs-ink-bar {
    display: none;
    color: var(--primary-color);
  }

  .ant-tabs-tab {
    margin: 0 4px !important;
    padding: 0 !important;
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    background: #fff;
  }

  .ant-tabs-tab-active .status-menu-title {
    color: #444;
    opacity: 0.9;
    transition: 0.3s;

    &:hover {
      color: #5867dd;
    }
  }

  .status-menu-card {
    display: inline-flex;
    flex-direction: column;
    padding: 8px;
    margin: 0 8px;
    transition: 0.2s;
    border: 3px solid transparent;

    .status-menu-title {
      font-size: 0.8rem;
    }

    .ant-statistic-content {
      font-size: 1.4rem;
    }
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;