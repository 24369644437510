.back-to-listing-container {
  padding: 8px;
  display: flex;
  align-items: center;
  color: var(--primary-color);

  .back-to-listing-button {
    margin: 0 4px;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .back-to-listing-divider {
    font-weight: 400;
    margin: 0 8px;
  }

  .back-to-listing-title {
    margin: 0 8px;
    color: #575962;
    font-size: 1.25rem;
    font-weight: 600;
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;