.logo-upload-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-upload-wrapper {
    width: 100%;
  }

  .radio-group-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .chosen-color {
    width: 101px;
    height: 101px;
    border-radius: 6px;
    margin-right: 4px;
    cursor: not-allowed;
  }

  .radio-group-colors {
    width: 76px;
  }

  .radio-button {
    margin: 2px !important;
    border-radius: 2;
    border: 2px solid transparent;
    width: 20px;
  }

  .ant-radio-button-checked {
    border: 2px dashed #ccc;
  }

  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-upload-list-picture-card-container {
    margin-bottom: 0 !important;
  }
  .ant-upload {
    margin: 0 !important;
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;