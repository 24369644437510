.erp-config-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.erp-config-taxes-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;

  .erp-config-taxes-item {
    // flex: 1;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 140px;
  }
}

.descriptions-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.descriptions-card {
  flex-basis: content;
  position: relative;
  border: 1px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 6px;
  margin: 4px;
  padding: 16px 24px;
}

.descriptions-card-taxes {
  position: relative;
  flex: 1;
  margin: 16px;
  max-width: 140px;
  border-radius: 6px 6px 0 0;
  border: 1px solid transparent;

  .descriptions-card-taxes-retained {
    position: absolute;
    left: 0;
    bottom: -38px;
    width: 100%;
    color: white;
    border-radius: 0 0 6px 6px;
    text-align: center;
  }
}

.descriptions-card-value {
  padding-left: 0;
  font-weight: bold;
  font-size: 1.2rem;
  opacity: 0.8;
  margin: 0;
}

.descriptions-card-select-account {
  color: #ffffff;
  position: absolute;
  left: -2px;
  top: -32px;
  padding: 4px;
  border: 4px solid transparent;
  border-radius: 6px 6px 6px 0;
}

@primary-color: #5867dd;@border-radius-base: 6px;