.mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    z-index: 2;
  }
  &--circle {
    width: 18px;
    height: 18px;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    background: #ff2f3b;
  }
  &-content {
    z-index: 1;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -9px);
    cursor: default;
    background: #fff;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    &__arrow {
      width: 10px;
      height: 10px;
      background: #fff;
      position: absolute;
      bottom: -3px;
      left: calc(50% - 4px);
      transform: translate(-50%);
      transform: rotate(45deg);
    }
  }
}

// Component CoverageMap
.coverage-map-marker {
  font-size: 1.5rem;
  border-radius: 5px;
  padding: 3px;
  color: #fff;
  &-static {
    background: #006400;
    box-shadow: 0px 6px 4px -1px #222, 0px 0px 0px 2.6px #239323;
  }
  &-click {
    background: var(--primary-color);
    box-shadow: 0px 6px 4px -1px #222, 0px 0px 0px 2.6px var(--primary-color);
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;