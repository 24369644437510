@keyframes fadeDown {
  to {
    opacity: 1;
    height: 100%;
  }
}

.filters-card .ant-card-body {
  opacity: 0;
  background: #f9f9f9;
  height: 1px;
  animation: fadeDown 0.2s linear forwards;
}

.filters-tabs {
  border-bottom: 2px solid #808fe671;
}

.filters-title {
  font-size: 16px;
  font-weight: normal;
}

.filters-button {
  margin: 0 10px;
}

@primary-color: #5867dd;@border-radius-base: 6px;