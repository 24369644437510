.action-lock-unlock {
  padding: 24px;
  width: 100%;
  text-align: center;
  display: flex !important;
  justify-content: center;
  .action-lock-unlock-radio {
    padding: 16px;
    max-width: 155px;
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 125px;
    justify-content: center;
    align-items: center;
    text-align: center;
    &:hover span > div {
      opacity: 0.6;
    }
    span > div {
      margin: 0 auto;
      margin-bottom: 4px;
    }
  }
}

.suspension-icons {
  display: block;
  width: 100%;
  font-size: 1.6rem;
}
.suspension-lock {
  svg {
    color: red;
  }
}
.suspension-unlock {
  svg {
    color: green;
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;