.ant-slider .ant-slider-rail {
  background: #ccc;
}
.ant-slider .ant-slider-track {
  background: blue;
  opacity: 0.6;
}
.clear-consumption-filter {
  opacity: 0.2;
  font-size: 20px;
  margin-right: 6px;
  width: 50px;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
}

.header-consumption-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  grid-gap: 3px
}
;

.wrapper-filter {
  padding-bottom: 5px;
}

.slider-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.range-input-wrapper {
  display: flex;
  align-content: center;
  align-items: center;
}
@primary-color: #5867dd;@border-radius-base: 6px;