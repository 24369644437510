.search-in-map {
  position: absolute;
  top: 10px;
  left: 10px;
  min-width: 240px;
  max-width: 400px;
  // width: 240px;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 1px 4px -1px rgba(0; 0; 0; 0.2);

  input {
    height: 40px;
    width: 100%;
    padding: 4px 56px 4px 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    outline-color: var(--primary-color);
  }

  .search-in-map-icon {
    font-size: 18px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
  }
}

.pac-container {
  border-radius: 6px;
  .pac-item {
    padding: 4px 12px;
  }

  &::after {
    display: none;
  }
}

.search-in-map-close {
  position: absolute;
  top: 50%;
  right: 36px;
  transform: translateY(-50%);
  color: #999;
  font-size: 14px;
  cursor: pointer;
  transition: 0.5s;
  display: flex;

  :hover {
    color: #555;
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;