.request-creation-error-table-cell {
  position: relative;
  background: #f9eae5 !important;
  transition: 0.3s;

  .ant-table-cell {
    position: static !important;
    border-top: 2px dashed #f7ac95;
    border-bottom: 2px dashed #f7ac95;
    &:first-child {
      padding-left: 40px !important;
      border-left: 8px solid #f7ac95 !important;
    }
  }

  .ant-table-cell-row-hover {
    background: #f7ddd5 !important;
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;