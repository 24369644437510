@import url('https://fonts.googleapis.com/css?family=Poppins:400&display=swap');
@import '~antd/dist/antd.less';

body,
html {
  display: block;
  block-size: border-box;
  top: 0;
  margin: 0;
  padding: 0;
  font-size: 90%;
  background-color: #f3faff;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}

.ant-card {
  box-shadow: 2px 2px 4px #f2f2f2;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.ant-table-tbody > tr > td {
  color: rgba(0, 0, 0, 0.65);
}

.ant-descriptions-item-content {
  color: rgba(0, 0, 0, 0.65);
}

.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 15px;
}

.ant-table-row-expand-icon::after {
  display: none;
}
.ant-table-row-expand-icon-expanded::before {
  content: '-';
}
.ant-table-row-expand-icon-collapsed::before {
  content: '+';
}
.ant-table-row-expand-icon::before {
  position: unset;
  transform: none;
  background: inherit;
  height: 100%;
}

.ant-table-row-expand-icon {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 13px;
}


.ant-carousel .slick-dots {
  z-index: 1 !important;
  li {
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    height: 6px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);

    button {
      height: 100%;
    }
  }
}

@media (max-width: 768px) {
  body,
  html {
    font-size: 90%;
  }
}

@media (max-width: 425px) {
  body,
  html {
    font-size: 90%;
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;