.super-list-container {
  padding: 16px;

  .select-wrapper {
    display: flex;
    align-items: center;

    .select-all {
      margin-right: 4px;
    }
  }

  .search-not-found {
    text-align: center;
    opacity: 0.8;
    margin-top: 32px;
    color: var(--primary-color);
  }

  .search-description {
    text-align: center;
    padding: 24px;
    opacity: 0.7;
    color: var(--primary-color);

    .search-description-icon {
      font-size: 1.4rem;
      margin: 6px;
      margin-bottom: 12px;
    }

    .search-description-text {
      max-width: 260px;
      margin: 0 auto;
    }
  }
  .search-loading {
    text-align: center;
    padding: 24px;
    opacity: 0.7;
    color: var(--primary-color);

    .search-loading-icon {
      font-size: 1.6rem;
      margin: 6px;
      margin-bottom: 12px;
    }
  }
  .selected-items {
    margin: 16px 0;

    .ant-divider > span {
      opacity: 0.8;
      font-size: 0.9rem;
      text-transform: uppercase;
    }
    .button-clear {
      margin-left: 16px;
    }
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;