.card-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .ant-tabs {
    padding: 0;
  }

  .ant-tabs-tab {
    margin: 0;

    .ant-tabs-ink-bar {
      padding: 20px;
    }
  }

  .ant-radio-button-wrapper {
    margin: 0;
    flex-wrap: wrap;

    span {
      font-weight: normal;
    }
  }
}

.total-lines-card {
  .ant-card-body {
    padding: 6px 20px;
    height: 577px;;
  }
}

.g2-html-annotation {
  opacity: 0.8;
  font-size: 1.3rem !important;
}
.g2-html-annotation + .g2-html-annotation {
  margin-top: 10px !important;
  font-size: 1.8rem !important;
}

@primary-color: #5867dd;@border-radius-base: 6px;