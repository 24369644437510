.ant-upload-select-picture {
  width: 100% !important;
}

.image-upload-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 65px;
  border: 2px dashed #dbdbdb;
  border-radius: 8px;
  padding: 8px !important;
  background: #fafafa;
  cursor: pointer;

  .image-upload-icon {
    font-size: 2.2rem;
    color: #dbdbdb;
    margin: 16px;
  }

  p {
    margin: 0 !important;
  }

  .image-upload-title {
    font-weight: bold;
    font-size: 0.9rem;
    opacity: 0.8;
    color: #444;
  }

  .image-upload-subtitle {
    opacity: 0.6;
    font-size: 0.8rem;
    max-width: 300px;
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;