.ant-tabs-tab {
  margin: 0 20px 0 0;
  padding: 12px 10px;
}

.badge-status-table-cell .ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

.col-line-consumption {
  text-align: center;
  max-width: 120px;

  .progress-bar-consumption {
    .ant-progress-inner {
      border: 1px solid #dbdbdb;
      max-width: 100px;
    }

    .ant-progress-bg,
    .ant-progress-success-bg {
      height: 10px !important;
    }
  }
}


@primary-color: #5867dd;@border-radius-base: 6px;