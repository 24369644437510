.select-context-switch {
  .ant-select-item-option:nth-child(n + 3) {
    border-top: 1px solid #dbdbdb;
  }
  .ant-select-item-option:first-of-type {
    border-top: none;
  }

  .ant-select-item-group {
    margin: 4px;
  }

  .ant-select-item-group:first-of-type {
    display: none;
  }

  .ant-select-item-option-selected {
    color: #fff !important;
    background: var(--primary-color) !important;
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;