.tab-pane-details {
  .ant-space {
    width: 100%;
    height: 100%;
  }
  .ant-badge {
    height: 100%;
  }
}

@primary-color: #5867dd;@border-radius-base: 6px;